import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Dialog from "@mui/material/Dialog";
import FormContainer from "../../components/request_properties/form_container";  // Importing FormContainer component

// ICONS IMPORT
import MenuIcon from "@mui/icons-material/Menu";

// ASSETS IMPORT
import logo from "../../assets/byd_new_logo.png";
import { HashLink } from "react-router-hash-link";
import './nav_bar.css';

import navItems from '../../components/utils/nav_items';
import socialMediaIcons from "../../components/utils/social_medias";

function NavBar() {
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
  const [appBar, setAppBar] = useState(false); // for app-bar scroll effect 
  const [isDialogOpen, setIsDialogOpen] = useState(false); // Dialog state

  // Toggle mobile drawer
  const toggleMobileDrawer = () => {
    setIsMobileDrawerOpen(!isMobileDrawerOpen);
  };

  // Open dialog for Property Request
  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  // Close dialog
  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  // Render the logo
  const renderLogo = (
    <img src={logo} alt="Inner Eye" style={{ width: "100%", height: "100%" }} />
  );

  // Render the navigation items
  const renderItems = (
    <>
      {navItems?.map((item, index) => (
        <Typography 
          key={index}
          variant="h6"
          fontWeight="500"
          sx={{
            color: "#000000",
            margin: { xs: "10px", sm: "0px 10px" },
            backgroundColor: "transparent",
            fontSize: { xs: "14px", sm: "16px", lg: "20px" },
            cursor: "pointer",
            letterSpacing: "1px"
          }}
          onClick={
            item.navItem === "Request Property" ? handleDialogOpen : null
          }
        >
          {item.navItem === "Request Property" ? (
            <span>{item.navItem}</span>
          ) : (
            <HashLink
              style={{ backgroundColor: "transparent", color: "#000000" }}
              to={item.itemLink}
            >
              {item.navItem}
            </HashLink>
          )}
        </Typography>
      ))}
    </>
  );

  // Social media links
  const socialMedia = (
    <Box 
      sx={{ 
        height: "auto",
        mx: { md: "0.5", lg: "0.9rem" }, 
        display: "flex",
        justifyContent: "center", 
        alignItems: "center",
      }}
    >
      {socialMediaIcons.map((icon, index) => (
        <IconButton key={index} aria-label={icon.label}>
          <icon.component />
        </IconButton>
      ))}
    </Box>
  );

  // Handle scroll effect for the AppBar
  const changingOnScroll = () => {
    if (window.scrollY >= 62) {
      setAppBar(true);
    } else {
      setAppBar(false);
    }
  };
  const bShadowOnScroll = "0px 2px 2px 0px rgba(28,36,49,0.3)";
  const bShadowOffScroll = "none";
  window.addEventListener('scroll', changingOnScroll);

  return (
    <AppBar 
      position="sticky" 
      sx={{ 
        width: "100%",
        backgroundColor: "#ffffff",
        top: 0,
        boxShadow: appBar ? bShadowOnScroll : bShadowOffScroll,
        padding: { sm: "0px 30px", lg: "0px 60px" },
        height: "auto"
      }}
    >
      <Toolbar
        disableGutters
        className="apply--maxwidth"
        sx={{ width: "100%", py: "0" }}
      >
        {/* MOBILE VIEW */}
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              height: "64px",
              margin: "4px 20px",
              maxWidth: "100px"
            }}
          >
            {renderLogo}
          </Box>
          <Box>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="black"
              onClick={toggleMobileDrawer}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Box>

        {/* DESKTOP VIEW */}
        <Box
          sx={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            display: { xs: "none", md: "flex" },
            padding: { xs: "0px", lg: "0px 40px" }
          }}
        >
          <Box sx={{ height: { lg: "100px" }, margin: "4px 0px" }}>
            {renderLogo}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", gap: { md: "0.5rem", lg: "1rem" } }}>
            {renderItems}
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Divider orientation="vertical" variant="middle" flexItem sx={{ borderLeftWidth: 1, borderColor: "#000000", height: "25px" }} />
            {socialMedia}
            <Divider orientation="vertical" variant="middle" flexItem sx={{ borderLeftWidth: 1, borderColor: "#000000", height: "25px" }} />
          </Box>
        </Box>
      </Toolbar>

      {/* Drawer for Mobile View */}
      <Drawer
        anchor="right"
        open={isMobileDrawerOpen}
        onClose={() => setIsMobileDrawerOpen(false)}
        sx={{ '& .MuiDrawer-paper': { width: 200, boxSizing: 'border-box' } }}
      >
        <Box>
          {renderItems}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: "50px" }}>
          <Typography>Follow Us On</Typography>
          {socialMedia}
        </Box>
      </Drawer>

      {/* Dialog for FormContainer */}
      <Dialog open={isDialogOpen} onClose={handleDialogClose} maxWidth="md" fullWidth={false}>
        <FormContainer onSubmit={handleDialogClose} />
      </Dialog>
    </AppBar>
  );
}

export default NavBar;
